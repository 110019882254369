/* eslint-disable no-restricted-syntax */
import {
    getDataFromLs,
    redirectToMain,
    saveDataToLs,
    scrollToYOfElement,
    sendGA4,
    sendLogs,
    showToast,
    testUnitHypothesis,
} from '@utils'

import {
    agreementCheckboxes,
    form,
    formToSubmit,
    password,
    registrationFormfirst, registrationFormsecond, registrationFormthird,
} from './forms'

export const preprocessing = async step => {
    formToSubmit[step] = Object.assign(formToSubmit[step], form[step])
    switch (step) {
    case 'first':
        formToSubmit.first.mobile_phone = form.first.mobile_phone?.replace(
            /[\s-+()]/g,
            '',
        )
        formToSubmit.first.email = form.first.email.trim()

        if (localStorage.getItem('developer_mode')) {
            formToSubmit.first.password = 'test_password'
        } else {
            formToSubmit.first.password = password.value
        }

        formToSubmit.first.sum = formToSubmit.first.sum ?? parseInt(localStorage.getItem('loanInfo-sum'))
        formToSubmit.first.period = formToSubmit.first.period ?? parseInt(localStorage.getItem('loanInfo-time'))

        break
    case 'second':
        formToSubmit.second.passport_number = form.second.passport_number?.replace(/[\s-]/g, '')
        break
    case 'third':

        if (formToSubmit.third.is_workless) {
            formToSubmit.third.work_place = ''
            formToSubmit.third.work_place_address = ''
            formToSubmit.third.work_phone = ''
        } else {
            formToSubmit.third.work_phone = form.third.work_phone?.replace(
                /[\s-+()]/g,
                '',
            )
        }
        if (testUnitHypothesis() === 'OCM_HIDDEN_DOP_PHONE') {
            formToSubmit.third.dop_phone = getDataFromLs('first')?.mobile_phone?.replace(
                /[\s-+()]/g,
                '',
            )
        } else {
            formToSubmit.third.dop_phone = form.third.dop_phone?.replace(
                /[\s-+()]/g,
                '',
            )
        }
        formToSubmit.third.is_workless = form.third.is_workless
            ? form.third.is_workless
            : form.third.pensioner
        break
    default:
    }
}

export const setErrorsBeforeSendingForm = stepIndex => {
    switch (stepIndex) {
    case 0:
        for (const [key, value] of Object.entries(registrationFormfirst.value.errors)) {
            if (value.length > 0) {
                scrollToYOfElement(registrationFormfirst.value.refs[key].id)
                return
            }
        }
        break
    case 1:
        for (const [key, value] of Object.entries(registrationFormsecond.value.errors)) {
            if (value.length > 0) {
                scrollToYOfElement(registrationFormsecond.value.refs[key].id)
                return
            }
        }
        break
    case 2:
        // обработка ошибки в поле заполнения данных по карте
        for (const [key, value] of Object.entries(registrationFormthird.value.errors)) {
            if (value.length > 0) {
                scrollToYOfElement(registrationFormthird.value.refs[key].id)
                return
            }
        }

        const checkBoxKeys = Object.keys(agreementCheckboxes.value.errors)
        // проверка заполнения всех нужных галочек
        for (let i = 0; i < checkBoxKeys.length; i++) {
            if (agreementCheckboxes.value.errors[checkBoxKeys[i]].length > 0) {
                const yOffset = -100
                const element = agreementCheckboxes.value.refs[checkBoxKeys[i]].$el
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset
                window.scrollTo({ top: y, behavior: 'smooth' })
                break
            }
        }
        break
    default:
    }
}
export const setErrorsAfterSendingForm = async (err, statusCode, step) => {
    await sendGA4('form-sent', `${step}-form-sent-failed`)
    if (typeof err === 'string' && err.includes('уже зарегистрирован') || typeof err === 'string' && err.includes('существует')) {
        await sendLogs(step, `redirect:${err}`)
        localStorage.setItem('accountExist', true)

        redirectToMain('/registration/first', `https://oneclickmoney.ru/registration/first`)
        const existingProfiles = getDataFromLs('existingProfiles') ?? {
            emails: [],
            phones: [],
            passports: [],
        }

        if (err.includes('email')) {
            existingProfiles.emails.push(form.first.email)
            saveDataToLs(existingProfiles, 'existingProfiles')
            registrationFormfirst.value.refs.email.applyResult({
                errors: [err],
                valid: false,
            })

            scrollToYOfElement(registrationFormfirst.value.refs.email.id)
        } else if (err.includes('паспорта')) {
            existingProfiles.passports.push(form.second.passport_number)
            saveDataToLs(existingProfiles, 'existingProfiles')
            registrationFormsecond.value.refs.passport_number.applyResult({
                errors: [err],
                valid: false,
            })
            scrollToYOfElement(registrationFormsecond.value.refs.passport_number.id)
        } else if (err.includes('телефоном') || err.includes('Мобильный телефон')) {
            existingProfiles.phones.push(form.first.mobile_phone)
            saveDataToLs(existingProfiles, 'existingProfiles')
            registrationFormfirst.value.refs.mobile_phone.applyResult({
                errors: [err],
                valid: false,
            })
            scrollToYOfElement(registrationFormfirst.value.refs.mobile_phone.id)
        }
    } else if (typeof err === 'string' && err.includes('Серия паспорта указана неверно') || typeof err === 'string' && err.includes('Номер паспорта не может быть')) {
        registrationFormsecond.value.refs.passport_number.applyResult({
            errors: [err],
            valid: false,
        })
        scrollToYOfElement(registrationFormsecond.value.refs.passport_number.id)
    } else if (step === 'second') {
        if (err.includes('Код подразделения')) {
            registrationFormsecond.value.refs.passport_issuer_code.applyResult({
                errors: [err],
                valid: false,
            })
        }
    } else if (statusCode === 502) {
        showToast({ text: 'Неизвестная ошибка', status: 'error' })
        window.scrollTo(0, 0)
    }
}
