<template>
  <div class='call-back-modal'>
    <div class='call-back-modal__content'>
      <div class='call-back-modal__title'>

        Обратный звонок

      </div>
      <div class='call-back-modal__description'>
        Хотите, чтобы специалист OneClickMoney позвонил вам и оформил заявку на займ до 30 000 руб. по телефону?
      </div>

      <validation-observer ref='callBackForm'>
        <div class='call-back-modal__form'>

          <div
            class='call-back-modal__field'
          >
            <c-input
              id='callBackPhone'
              type='mobilePhone'
              :value='form.phone'
              placeholder='Номер телефона'
              @changeValue='(newValue) => form.phone = newValue'
            />

          </div>
          <div
            class='call-back-modal__field'
          >
            <c-input
              id='callBackName'
              :value='form.name'
              placeholder='Ваше имя'
              @changeValue='(newValue) => form.name = newValue'
            />
          </div>

        </div>
      </validation-observer>
      <div class='call-back-modal__buttons'>

        <c-button
          id='call-back-submit'
          title='Заказать звонок'
          @onClick='{}'
        />

        <c-button
          id='call-back-cancel'
          title='Отменить'
          type='outlined'
          @onClick='{}'
        />
      </div>
    </div>
  </div>
</template>

<script>
import CButton from "@components/CButton.vue"
import CInput from '@components/form/CInput.vue'
import { ValidationObserver } from 'vee-validate'

export default {
    components: {
        ValidationObserver,
        CInput,
        CButton,
    },
    data() {
        return {
            form: {
                name: '',
                phone: '',

            },
        }
    },

}
</script>

<style lang='scss'>
@import "@core/scss/base/bootstrap-extended/_variables.scss";
::placeholder {
  font-family: "ConsolaMonoBold", sans-serif;
  color: $on-background !important;
}
.call-back-modal {
  &__content {
    max-width: 500px;
    margin: 0 auto;
  }
  &__title {
    width: 100%;
    font-size: 30px;
    font-family: "ConsolaMonoBold", sans-serif;
    text-align: center;
    @media only screen and (max-width: 1440px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 640px) {
      font-size: 21px;
    }
    @media only screen and (max-width: 375px) {
      line-height: 26px;
    }
  }
  &__description {
    text-align: center;

    font-size: 18px;

    margin: 20px 0 0;

    @media only screen and (max-width: 1200px) {
      font-size: 16px;
      margin: 24px 0 0;
    }
    @media only screen and (max-width: 425px) {
      font-size: 15px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    background-color: $surface;
    padding: 0 20px 20px;
    margin-top: 20px;
    width: 100%;

    @media only screen and (max-width: 1200px) {
      padding: 24px;
      margin-top: 24px;
    }
  }
  &__field {
    margin-bottom: 0;
    width: auto;
    margin-top: 20px;
    float: none;
    padding: 0;
    height: 50px;
    margin: 0 0 25px;
    font-size: 16px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    @media only screen and (max-width: 440px) {
      flex-direction: column;
    }
  }
}
</style>
