<template>
  <c-modal
    key='loginModal'
    :width='600'
    :toggle='toggle'
    @closeModal="$emit('closeModal')"
    @openModal="$emit('openModal')"
  >
    <template #content>
      <div class='login-modal-wrap'>
        <div class='login-modal'>
          <div class='login-modal__title'>
            <p>Войти в личный кабинет</p>
          </div>
          <div class='login-modal__form'>
            <div class='login-modal__form__item'>
              <c-input
                id='loginform-username'
                :value='user.email'
                placeholder='Email или телефон'
                @changeValue='(newValue) => (user.email = newValue)'
              />
            </div>
            <div class='login-modal__form__item'>
              <div class='pass-inp-group'>
                <c-input
                  id='loginform-userPassword'
                  type='password'
                  :value='user.password'
                  placeholder='Пароль'
                  @changeValue='(newValue) => (user.password = newValue)'
                />
              </div>
              <div class='error-page-field' />
            </div>
            <div class='forget-me'>
              <div class='login-remember-me'>
                <div class='login-remember-me__wrap'>
                  <label
                    for='loginform-rememberme'
                    :class="{ 'login-remember-me-checked': user.rememberme }"
                  >
                    <input
                      id='loginform-rememberme'
                      v-model='user.rememberme'
                      class='d-none'
                      type='checkbox'
                      @click='user.rememberme = !user.rememberme'
                    >

                    Запомнить
                  </label>
                </div>
              </div>
              <a
                class='fg-pass'
                @click='restore'
              > Восстановить пароль? </a>
            </div>
          </div>

          <div class='login-modal__buttons'>
            <button
              class='login-modal__buttons__login'
              @click='login'
            >
              Войти
            </button>
            <b-link
              class='login-modal__buttons__register'
              href='/registration/first'
            >
              Регистрация
            </b-link>
          </div>
        </div>
      </div>
    </template>
  </c-modal>
</template>

<script>
import CModal from '@components/CModal.vue'
import CInput from '@components/form/CInput.vue'
import { redirectToMain, sendLogs } from '@utils'
import { BLink } from 'bootstrap-vue'
import RandExp from 'randexp'

export default {
    components: {
        CModal,
        CInput,
        BLink,
    },
    props: {
        toggle: {
            type: Boolean,
            require: true,
            default: false,
        },
    },
    data() {
        return {
            user: {
                email: '',
                password: '',
                rememberme: false,
            },
        }
    },
    created() {
        if (localStorage.getItem('developer_mode') === 'true') {
            this.user = {
                email: new RandExp(/[a-z]{6}^(@mail\.ru)$/gm).gen(),
                password: new RandExp(/[a-z]{6}^[1-9]{2}^(!)^[A-Z]{1}$/gm).gen(),
                rememberme: true,
            }
        }
    },
    methods: {
        async login() {
            await sendLogs('redirect', `login:${this.$route.path}`)
            redirectToMain({ log: 'redirect_already_registred', path: this.$route.path, url: 'https://oneclickmoney.ru/#signin' })
        },
        async restore() {
            await sendLogs('redirect', `reset_password:${this.$route.path}`)
            redirectToMain({ log: 'redirect_already_registred', path: this.$route.path, url: 'https://oneclickmoney.ru/#restore' })
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_variables.scss';
.login-modal-wrap {
  height: auto;
  max-height: 90%;
  min-height: 580px;
  @media only screen and (min-width: 768px) {
    height: auto;
    min-height: initial;
  }

}
.login-modal {
  background-color: $background;
  width: 100%;
  max-width: 500px;
  padding: 0;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  @media only screen and (max-width: 425px) {
    display: block;
  }

  &__form {
    display: flex;
    background-color: $surface;
    padding: 0 20px 20px;

    width: 100%;
    flex-direction: column;
    &__item {
      margin-top: 30px;
      @media only screen and (max-width: 1440px) {
        margin: 18px 0 0;
      }
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    @media only screen and (max-width: 425px) {
      flex-direction: column;
    }

    &__login {
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      color:  $on-secondary-surface;
      background: $secondary-surface;
      transition: all 0.2s ease-out;
      font: 24px 'ConsolaMonoBold', sans-serif;
      @media only screen and (max-width: 1440px) {
        font-size: 18px;
      }
      @media only screen and (min-width: 768px) {
        padding: 20px 40px;
      }
      @media only screen and (max-width: 425px) {
        margin: 0;
        padding: 10px 20px;
        width: 100%;
        text-align: center;
        font-size: 16px;
      }
    }
    &__register {
      border: none;
      cursor: pointer;
      color: $on-surface !important;
      background: $surface;
      padding: 10px 20px;
      transition: all 0.2s ease-out;
      margin: 0;
      font: 24px 'ConsolaMonoBold', sans-serif;
      text-decoration: none !important;
      position: relative;
      @media only screen and (min-width: 768px) {
        padding: 20px 40px;
      }
      @media only screen and (max-width: 1440px) {
        font-size: 18px;
        margin: 0 40px 0 0;
      }
      @media only screen and (max-width: 425px) {
        margin: 10px 0 0 !important;
        padding: 10px 20px !important;
        width: 100% !important;
        text-align: center !important;
        font-size: 16px !important;
      }
    }
  }

  &__title {
    width: 100%;
    margin-bottom: 30px;
    margin-top: 0;
    @media only screen and (max-width: 425px) {
      margin-bottom: 20px;
    }
    > p {
      @media only screen and (max-width: 1440px) {
        font-size: 24px;
      }
      @media only screen and (max-width: 640px) {
        font-size: 21px;
      }
      @media only screen and (max-width: 375px) {
        line-height: 26px;
      }
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 30px;
      font-family: 'ConsolaMonoBold', sans-serif;
      text-align: center;
    }
  }
}

.esia-login-box {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 30px 0;
  padding: 0;
  @media only screen and (max-width: 420px) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
  }
}

.esia-btn {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  padding: 0;
  > a {
    display: flex;
    align-items: center;
    padding: 0;
    width: auto;
  }
}
.gos1 {
  margin: 0 20px 0 0;
  color: $secondary;
  font-size: 18px;
  font-weight: 700;
  display: block;
  position: relative;
  float: inherit;
  margin: 0 20px 0 0;
  width: inherit;
  float: left;
  @media only screen and (max-width: 290px) {
    float: none;
  }
}
.gos2 {
  background-image: url(@/assets/images/ocm/components/partners/gosuslugi-logo1.png);

  margin-left: auto;

  display: block;
  position: relative;

  background-repeat: no-repeat;
  padding: 0;
  width: 40px;
  height: 44px;
  background-size: cover;
  float: inherit;

  @media only screen and (max-width: 290px) {
    margin: 0 auto;
    float: none;
  }
}

.forget-me {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    margin-top: 18px;
  }

  @media only screen and (max-width: 375px) {
    flex-direction: column;
    align-items: start;
  }
}
.fg-pass {
  color: $secondary !important;
  font-size: 16px;
  padding: 0;
  background: 0 0;
  cursor: pointer;
  display: inline;
  border: none;
  position: relative;
  transition: all 0.2s ease-out;
  @media only screen and (max-width: 425px) {
    font-size: 14px;
  }
}
.login-remember-me {
  margin-bottom: 0;
  float: none;
  padding: 0;
  width: auto;

  &__wrap {
    width: auto;
    position: relative;

    > input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
      @media only screen and (max-width: 425px) {
        display: none;
      }
    }

    > label {
      margin-bottom: 0 !important;
      font-family: 'ConsolaMono', sans-serif;
      line-height: 27px;
      position: relative;
      padding: 1px 0 0 35px;
      line-height: 28px;

      @media only screen and (max-width: 1440px) {
        line-height: 22px;
        padding: 0 35px 0;
        font-size: 16px;
      }

      @media only screen and (max-width: 425px) {
        padding-top: 0;
        line-height: 18px;
        font-size: 14px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 1px;
        width: 16px;
        height: 16px;
        border: 1px solid $on-background;
        background: $background;
        box-sizing: border-box;
        @media only screen and (max-width: 425px) {
          top: 3px;
        }
      }
      &:after {
        content: '';
        position: absolute;
        top: 7px;
        left: 1px;
        width: 16px;
        height: 16px;
        transition: all 0.2s ease-out;
        background: url(@/assets/images/ocm/registration/check.png) no-repeat
          center;
        background-size: 100% 100%;
        opacity: 0;
        transition: all 0.2s ease-out;
        @media only screen and (max-width: 425px) {
          top: 3px;
        }
      }
      &:hover {
        &:after {
          opacity: 0.4;
        }
      }
    }
  }
  &-checked {
    &:after {
      opacity: 1 !important;
    }
  }
}

.custom-input {
  margin-bottom: 0 !important;
}
.form-group {
  margin-bottom: 0;
}
</style>
