/* eslint-disable no-restricted-syntax */
import Lean from '@/api/lean'
import {
    redirectToMain,
    sendLogs,
} from '@utils'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'vue'

export default function useInitialization() {
    const leanTestId = ref(null)

    const utmSource = ref(null)
    const utmMedium = ref(null)
    const utmCampaign = ref(null)

    const part = ref(null)

    async function initUser() {
        leanTestId.value = new URLSearchParams(window.location.search).get('lean_test_id')
        utmSource.value = new URLSearchParams(window.location.search).get('utm_source')
        utmMedium.value = new URLSearchParams(window.location.search).get('utm_medium')
        utmCampaign.value = new URLSearchParams(window.location.search).get('utm_campaign')

        localStorage.setItem('utmSource', utmSource.value)

        if (utmSource.value || utmMedium.value || utmCampaign.value) {
            localStorage.setItem('utm_was_added', true)
        }

        if (!localStorage.getItem('utm_was_added')) {
            localStorage.clear()
            redirectToMain({ log: 'redirect_already_registred' })
        }

        if (leanTestId.value && !localStorage.getItem('lean_test_id')) {
            await show().then(async userId => {
                await init(userId)
            })
        }
    }

    // *====== обращения к api
    async function show() {
        return await Lean.show(leanTestId.value)
            .then(async res => {
                if ((!res?.body || res.statusCode !== 200)) {
                    return
                }
                if (res.body.count_a + res.body.count_b >= res.body.max_traffic) {
                    redirectToMain({ log: 'redirect_already_registred' })
                }

                const utmPart = new URLSearchParams(window.location.search).get('part')

                if (utmPart && utmPart === 'B') {
                    part.value = 'B'
                } else {
                    part.value = res.body.count_a <= res.body.count_b ? 'A' : 'B'
                }

                localStorage.setItem('part', part.value)
            })
            .catch()
    }

    async function init() {
        const accessToken = uuidv4()
        localStorage.setItem('access_token', accessToken)

        await Lean.init({
            lean_test_id: leanTestId.value,
            access_token: accessToken,
            utm_source: utmSource.value,
            utm_medium: utmMedium.value,
            utm_campaign: utmCampaign.value,
            part: part.value,
        })
            .then(async res => {
                if (res.statusCode !== 200) {
                    if (res.statusCode === 500 && res?.error === "Lean user already exists") {
                        await sendLogs('landing', 'init_error: Lean user already exists')
                        Lean.init({
                            lean_test_id: leanTestId,
                            access_token: accessToken,
                            utm_source: utmSource.value,
                            utm_medium: utmMedium.value,
                            utm_campaign: utmCampaign.value,
                            part: part.value,
                        }).then(async result => {
                            if (result.statusCode === 200) {
                                setUser(result.body.id)
                            }
                        }).catch()
                    }
                } else {
                    setUser(res.body.id)
                }
            })
            .catch()
    }

    function setUser(userId) {
        localStorage.setItem('userIsInited', true)
        localStorage.setItem('user_id', userId)
        localStorage.setItem('lean_test_id', leanTestId.value)
    }

    return {
        initUser,
    }
}
